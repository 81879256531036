* {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

#root{
  display: flex;
}

:root {
  --white: #FFFFFF;
  --black: #000000;
  --lightgray: #F3F3F3;
  --gray: #606060;
  --green: #00af5a;
  --red: #ff4c4c;
  --blue: #0097FE;
  --blue-2: #0083da;
  --light-blue: #34CFCA;
  --light-blue-2: #2CB1AD;
}